// import moment from 'moment';
// eslint-disable-next-line import/prefer-default-export
export const calcSubDaysLeft = (nextBillDate: number): number => {
  const currentDate = new Date();
  const expirationDate = new Date(nextBillDate * 1000);

  if (expirationDate < currentDate) return 0;

  const diffTime = expirationDate.getTime() - currentDate.getTime();
  const diffDays = Math.round(diffTime / (1000 * 3600 * 24));
  return diffDays;
};

export const defaultStartYear = 1930;

export const dateDifferenceInHours = (fromDate: Date, toDate: Date): number => {
  const diffInHours = Math.floor(Math.abs(toDate.getTime() - fromDate.getTime()) / 3600000);

  return diffInHours;
};

export const getDay = (date: Date = new Date()): number => date.getUTCDate();

export const getMonth = (date: Date = new Date()): number => date.getMonth();

export const getWeek = (date: Date = new Date()): number => {
  const yearStart = +new Date(date.getFullYear(), 0, 1);
  const today = +new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const dayOfYear = (today - yearStart + 1) / 86400000;
  const week = Math.ceil(dayOfYear / 7);

  return week;
};

export const getYear = (date: Date = new Date()): number => date.getFullYear();

export const labelAgo = (label: string, value: number) => {
  const intervalValue = Math.floor(value);
  return intervalValue === 1 ? `1 ${label} ago` : `${intervalValue} ${label}s ago`;
};

export const timeAgo = (date: string) => {
  const seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000);
  let interval = seconds / 31536000;
  if (interval > 1) return labelAgo('year', interval);
  interval = seconds / 2592000;
  if (interval > 1) return labelAgo('month', interval);
  interval = seconds / 86400;
  if (interval > 1) return labelAgo('day', interval);
  interval = seconds / 3600;
  if (interval > 1) return labelAgo('hour', interval);
  interval = seconds / 60;
  if (interval > 1) return labelAgo('minute', interval);
  return labelAgo('second', interval);
};

export const getLocalIsoDateTime = (dtString: string) => {
  if (dtString === '') return '';
  const offset = new Date().getTimezoneOffset();
  const absO = Math.abs(offset);
  const sign = offset < 0 ? '+' : '-';
  const no1 = Math.floor(absO / 60)
    .toString()
    .split('')
    .slice(-2);
  const no2 = (absO % 60).toString().split('').slice(-2);

  const zone = `${sign}${no1.length > 1 ? '' : 0}${no1}:${no2.length > 1 ? '' : 0}${no2}`;
  return dtString + zone;
};

export const formatDate = (dateFormat: string, date = new Date()): string => {
  switch (dateFormat) {
    case 'MMMM D, YYYY': {
      return new Intl.DateTimeFormat('en-CA', {
        dateStyle: 'long',
      }).format(date);
    }
    case 'LT': {
      return new Intl.DateTimeFormat('en-US', {
        timeStyle: 'short',
      }).format(date);
    }
    case 'MMM DD YYYY': {
      return new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      })
        .format(date)
        .replace(',', '');
    }
    default: {
      return date.toISOString();
    }
  }
};

export const dayOfWeek = (): string => {
  const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const today = new Date();

  return weekday[today.getDay()];
};

export const generateYearsInRange = (startYear: number, endYear: number) =>
  Array.from({ length: endYear - startYear + 1 }, (item, i) => startYear + i);

export const generateDaysForMonth = (month: number, year: number) =>
  Array.from({ length: new Date(year, month, 0).getDate() }, (item, i) => i + 1);

export const getNumericMonths = () => Array.from({ length: 12 }, (item, i) => i + 1);

export const getMonthName = (monthNum: number) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return monthNames[monthNum - 1];
};

export const validateYear = (year: number) => {
  const thisYear = getYear();

  if (defaultStartYear > year || thisYear < year) {
    return false;
  }

  return true;
};

const inFuture = (date: Date) => date.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0);

export const validateMonth = (param: string, month: string) => {
  const parsedMonth = parseInt(month, 10);
  if (Number.isNaN(parsedMonth) || parsedMonth < 1 || parsedMonth > 12) {
    return false;
  }

  const requestedDate = new Date(`${param}-${month}-1`);

  if (inFuture(requestedDate)) {
    return false;
  }
  return true;
};

export const validateDay = (param: string, month: string, day: string) => {
  const parsedDay = parseInt(day, 10);

  if (Number.isNaN(parsedDay) || parsedDay < 1 || parsedDay > 31) {
    return false;
  }

  const requestedDate = new Date(`${param}-${month}-${day}`);

  if (Number.isNaN(requestedDate.getTime()) || inFuture(requestedDate)) {
    return false;
  }

  return true;
};

export const secondsToMinAndSeconds = (sec: number) => {
  const minutes = Math.floor(sec / 60);
  const seconds = Math.round(sec - minutes * 60);
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${minutes}:${formattedSeconds}`;
};

export function yearFrom(yearsAgoCount: number): number {
  const currentYear = new Date().getFullYear();
  const startingYear = currentYear - yearsAgoCount;
  return startingYear;
}
