export const ARTICLE_PAGE_TEST_IDS = {
  AUTHORS: 'article-authors',
  AUTHOR_AND_DATE: 'article-author-and-date',
  CAPTION: 'caption',
  CD_RATES_WIDGET: 'cd-rates-widget',
  CONTAINER_EYEBROW: 'article-container-eyebrow',
  CONTENT: 'article-content',
  CREDIT: 'credit',
  EYEBROW: 'article-eyebrow',
  IMAGE: 'article-image',
  RIGHT_RAIL: 'article-right-rail',
  SAVINGS_WIDGET: 'savings-widget',
  SCHOOL_PAGE_BANNER: 'school-page-banner',
  SCHOOL_PAGE_RIGHT_RAIL: 'school-page-right-rail',
  SECTION_EYEBROW: 'article-section-eyebrow',
  SERIES_LIST: 'article-series-list',
  SERIES_TOP: 'article-series-top',
  TAG_EYEBROW: 'article-tag-eyebrow',
  TITLE: 'article-title',
};

export type TestIds = {
  [key in keyof typeof ARTICLE_PAGE_TEST_IDS]?: string;
};

const getArticlePageTestId = (value: keyof TestIds) => ARTICLE_PAGE_TEST_IDS[value];

export default getArticlePageTestId;
